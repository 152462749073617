// handles jwt auth

import axios from 'axios';

class AuthService {
    // submits credentials to server to get jwt token
    // if valid credentials, stores jwt token in local storage and return true
    // if not valid, return false
    async login(user) {
        const response =  await axios
        .post(process.env.VUE_APP_BASE_API_URL + '/api/website/jwt/', {
            username: user.username,
            password: user.password,
        })
        .then(response => {
            if (response.data.access) {
                localStorage.setItem('access_token', response.data.access);
                localStorage.setItem('refresh_token', response.data.refresh);
            }
            return response.data;
        })
        .catch(error => {
            // invalid credentials generate a 401 error.
            return false;
        });

        // returning directly does not give a boolean
        if (response.access && response.refresh) {
            return true;
        } else {
            return false;
        }
       
    }
    
    logout() {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
    }
    
    getAccessToken() {
        return localStorage.getItem('access_token');
    }
    
    getRefreshToken() {
        return localStorage.getItem('refresh_token');
    }
}

export default new AuthService();